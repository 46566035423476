/**
 * @prettier
 */
import React from 'react' // eslint-disable-line no-restricted-imports
import ReactDOM from 'react-dom'
import { observer } from 'mobx-react'
import $ from 'jquery'
import { UIIcon } from 'talkable-ui-kit'

import { notificationsStore } from './store'
import { NotificationsList } from './styled'

export const Notifications = observer(
  class Notifications extends React.PureComponent {
    componentDidMount() {
      const flashNotifications = JSON.parse(
        document.querySelector('.js-flash-notifications')?.dataset.value || '[]',
      )

      if (flashNotifications && flashNotifications.length > 0) {
        const normalizeData = this.normalize(flashNotifications)

        normalizeData.forEach(item => {
          notificationsStore.add(item)
        })
      }
    }

    handleClick = id => () => {
      $(`[data-notification-id=${id}]`).each(function () {
        $(this).css({ height: $(this).outerHeight() })
      })
      notificationsStore.removeFromScreen(id)
    }

    normalize = data => {
      return data.map(([type, text]) => {
        return new Map([
          ['type', type],
          ['text', text],
        ])
      })
    }

    getIconName = type => {
      switch (type) {
        case 'success':
          return 'successNotification'
        default:
          return 'errorNotification'
      }
    }

    componentWillUnmount() {
      const { clearStore } = notificationsStore

      clearStore()
    }

    render() {
      const { items } = notificationsStore

      if (items.size === 0) return null

      return (
        <NotificationsList>
          <ul className="Notification">
            {Array.from(items.entries()).map(([id, data]) => {
              return (
                <li
                  data-testid="ac-notification"
                  data-notification-id={id}
                  className={`Notification-item Notification-is-${data.type}`}
                  key={id}
                >
                  <div className="Notification-item-wrap">
                    {data.type !== 'notice' && (
                      <div className="Notification-item-icon ac-notification-item-icon">
                        <UIIcon name={this.getIconName(data.type)} />
                      </div>
                    )}
                    <div className="Notification-item-text">
                      <div dangerouslySetInnerHTML={{ __html: data.text }} />
                    </div>
                  </div>
                  <div
                    data-testid="ac-notification-item-close"
                    onClick={this.handleClick(id)}
                    className="Notification-item-close"
                  >
                    <UIIcon name="closeNotification" />
                  </div>
                </li>
              )
            })}
          </ul>
        </NotificationsList>
      )
    }
  },
)

export default {
  mount: () => {
    document.addEventListener('DOMContentLoaded', () => {
      const rootNotifications = document.createElement('div')

      rootNotifications.setAttribute('id', 'notifications')
      document.body.appendChild(rootNotifications)
      ReactDOM.render(<Notifications />, document.getElementById('notifications'))
    })
  },

  add: ({ type, text, delay }) => {
    notificationsStore.add(
      new Map([
        ['type', type],
        ['text', text],
        ['delay', delay],
      ]),
    )
  },

  showSuccessFlash: (text = 'Changes have been saved', delay = 6000) => {
    notificationsStore.add(
      new Map([
        ['type', 'success'],
        ['text', text],
        ['delay', delay],
      ]),
    )
  },

  showErrorFlash: (text = 'Error saving changes', delay = 6000) => {
    notificationsStore.add(
      new Map([
        ['type', 'error'],
        ['text', text],
        ['delay', delay],
      ]),
    )
  },
}
