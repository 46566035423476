/**
 * @prettier
 */
import $ from 'jquery'
import get from 'lodash/get'

import { AccessModal } from 'components/shared/access_modal'
import { isAccessDeniedStatus } from 'utils/ajax'

export default () => {
  $(document).ajaxError((event, xhr, settings) => {
    if (isAccessDeniedStatus(xhr) && !document.getElementById('access-modal')) {
      AccessModal.mount()
    }
    if (xhr.status === 500) {
      notifyDevelopers({
        message: `Unable to ajax ${get(settings, 'url')} ${get(settings, 'type')}`,
        name: 'Ajax failure 500',
        xhr,
      })
    }
  })
}
