/**
 * @prettier
 */
import styled from 'styled-components'

export const NotificationsList = styled.div`
  .Notification {
    position: fixed;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    z-index: 2147483647;
    transition: all 2s ease-in-out;
    margin-left: 0;

    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      width: 360px;
      margin-top: 10px;
      color: #fff;
      padding: 15px 8px 15px 15px;
      border-radius: 6px;
      opacity: 1;
      animation: fade-right 0.4s ease-in-out;
      animation-fill-mode: backwards;
      box-sizing: border-box;

      &-wrap {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-right: 1px solid rgba(255, 255, 255, 0.2);
        padding-right: 15px;
      }

      &-icon {
        margin-right: 15px;
      }

      &-text {
        display: block;
        /* because cascade from body */
        font-family: 'gotham-htf-medium', 'aktiv-grotesk', Arial, sans-serif !important;
        margin: 0;
        padding-right: 15px;
        font-size: 14px;
        line-height: 1.3;
        word-break: break-word;

        p {
          margin: 0;
          padding: 0;
        }

        a {
          text-decoration: underline;
          color: #fff;
          cursor: pointer;
        }
      }

      &-close {
        margin-left: 15px;
        cursor: pointer;

        .sign-svg {
          vertical-align: baseline;
        }
      }
    }

    &-remove {
      transform: translateX(200%);
      opacity: 0;
      transition: all 0.4s ease-in-out;
    }

    &-is-success {
      background-color: rgba(13, 167, 82, 0.9);
      box-shadow: 0 4px 10px rgba(13, 167, 82, 0.3);
    }

    &-is-error,
    &-is-warning,
    &-is-alert {
      background-color: rgba(223, 20, 10, 0.9);
      box-shadow: 0 4px 10px rgba(163, 16, 27, 0.3);
    }

    &-is-notice {
      background-color: rgba(37, 131, 219, 0.9);
      box-shadow: 0 4px 10px rgba(8, 75, 138, 0.3);
    }

    @keyframes fade-right {
      0% {
        height: 0;
        margin: 0;
        padding: 0;
        transform: translateX(200%);
      }

      50% {
        transform: translateX(200%);
      }

      100% {
        transform: translateX(0%);
      }
    }
  }
`
