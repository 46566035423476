/**
 * @prettier
 */

/*
  This is the fix for incorrect calculation of UTC offset when dealing with daylight savings time.
  PR that should fix it: https://github.com/iamkun/dayjs/pull/1448

  TODO: Update library after PR is merged
*/
export const utcOffsetFix = (option, dayjsClass) => {
  dayjsClass.prototype.valueOf = function () {
    const addedOffset = !this.$utils().u(this.$offset)
      ? this.$offset + (this.$x.$localOffset || this.$d.getTimezoneOffset())
      : 0

    return this.$d.valueOf() - addedOffset * 60 * 1000
  }
}
