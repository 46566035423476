/**
 * @prettier
 */
import { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { UIInputSelect } from 'talkable-ui-kit'

import { CustomCurrencySelect } from 'components/shared/custom_currency_select'

export const CurrencySelectWithDivider = styled(UIInputSelect)`
  z-index: 2;

  .react-select {
    &__option:nth-child(${props => props.dividerAfter || 5}) {
      border-bottom: 2px solid #eee;
    }
`

export const CurrencySelect = ({ currencies, value, disableSelect, explanationText }) => {
  const currenciesOptions = useMemo(
    () =>
      currencies.map(curr => ({
        label: `${curr.name} (${curr.iso_code}, ${curr.unit || curr.symbol})`,
        value: curr.iso_code,
        symbol: curr.symbol,
      })),

    [currencies],
  )

  const [isAmountDiscount, setIsAmountDiscount] = useState(false)
  const handleUpdateSymbol = symbol => {
    const currencyUnit = document.querySelector('.currency-unit')
    const discountText = document.querySelector('.discount-text')

    if (currencyUnit) {
      currencyUnit.textContent = `${symbol} Amount`
      discountText.textContent = `Give 25% off, or give ${symbol}25.00 off?`
    }
  }

  useEffect(() => {
    const radios = document.querySelectorAll(
      'input[name="coupon_list[percentage_discount]"]',
    )

    if (radios.length > 0) {
      const handleChange = event => {
        setIsAmountDiscount(event.target.value === 'false')
      }

      radios.forEach(radio => {
        if (radio.checked) {
          setIsAmountDiscount(radio.value === 'false')
        }
        radio.addEventListener('change', handleChange)
      })

      return () => {
        radios.forEach(radio => radio.removeEventListener('change', handleChange))
      }
    }
  }, [])

  return (
    <CustomCurrencySelect
      disableSelect={disableSelect}
      explanationText={explanationText}
      currencies={currenciesOptions}
      showSelect={isAmountDiscount}
      currencySymbolUpdater={handleUpdateSymbol}
      value={value}
    />
  )
}
