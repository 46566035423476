/**
 * @prettier
 */
import { observable, action, values } from 'mobx'
import isFinite from 'lodash/isFinite'
import { nanoid } from 'nanoid'
import $ from 'jquery'

const DEFAULT_DELAY = 6000

class Store {
  items = observable(new Map())

  checkTypeNotification = type =>
    ['success', 'warning', 'error', 'notice', 'alert'].includes(type)

  add = action(data => {
    if (!this.checkTypeNotification(data.get('type'))) {
      throw new Error('Invalid data type')
    }

    if (
      data.get('text') &&
      values(this.items).some(item => item.text === data.get('text'))
    ) {
      return
    }

    const id = nanoid()

    if (data.get('text')) {
      this.items.set(id, {
        type: data.get('type'),
        text: data.get('text'),
      })
    }

    if (data.get('delay') === Infinity) {
      return
    }

    setTimeout(
      () => {
        this.removeFromScreen(id)
      },
      isFinite(data.get('delay')) ? data.get('delay') : DEFAULT_DELAY,
    )
  })

  removeFromScreen = id => {
    const currentElement = $(`[data-notification-id=${id}]`)
    const self = this

    currentElement.addClass('Notification-remove')
    currentElement.on('transitionend webkitTransitionEnd oTransitionEnd', function () {
      $(this).css({
        height: 0,
        margin: 0,
        padding: 0,
      })

      if ($(this).css('height') === '0px') {
        self.remove(id)
      }
    })
  }

  remove = action(id => {
    this.items.delete(id)
  })

  clearStore = action(() => {
    this.items.clear()
  })
}

export const notificationsStore = new Store()
