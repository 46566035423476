/**
 * @prettier
 */
import styled from 'styled-components'
import { useState } from 'react'

import { CurrencySelectWithDivider } from 'components/shared/currency_select'

const WrapperSelect = styled.div`
  width: ${({ width }) => width};
  text-align: left;
`

export const CustomCurrencySelect = ({
  currencies,
  disableSelect = false,
  explanationText = '',
  showSelect = true,
  currencySymbolUpdater = () => {},
  value,
}) => {
  const [currencyValue, setCurrencyValue] = useState(
    currencies.find(currency => currency.value === value),
  )

  const handleChangeCurrency = newValue => {
    setCurrencyValue(newValue.selectedOption)
    if (currencySymbolUpdater) {
      currencySymbolUpdater(newValue.selectedOption.symbol)
    }
  }

  return showSelect ? (
    <>
      <div>
        Currency{' '}
        {disableSelect && (
          <a title={explanationText} data-toggle="tooltip" data-placement="top">
            <i className="icon icon-question">
              <span className="sign">&amp;#63;</span>
            </i>
          </a>
        )}
      </div>
      <WrapperSelect width="340px">
        <CurrencySelectWithDivider
          disabled={disableSelect}
          className="js-currency-select"
          id="coupon_list_amount_currency"
          searchable={true}
          name="coupon_list[amount_currency]"
          placeholder="Select currency"
          options={currencies}
          onChange={handleChangeCurrency}
          value={currencyValue?.value || null}
        />
        <div data-validate="custom_currency_select" />
      </WrapperSelect>
    </>
  ) : null
}
