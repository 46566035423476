/**
 * @prettier
 */
import ReactDOM from 'react-dom'
import { UIModalConfirmation } from 'talkable-ui-kit'

export class AccessModal extends React.Component {
  static mount = () => {
    let root = document.createElement('div')

    root.setAttribute('id', 'access-modal')
    document.body.appendChild(root)
    ReactDOM.render(<AccessModal />, root)
  }

  reloadPage = () => {
    window.location.reload()
  }

  onCloseModal = () => {
    const container = document.getElementById('access-modal')

    ReactDOM.unmountComponentAtNode(container)
    document.body.removeChild(container)
  }

  render() {
    return (
      <UIModalConfirmation
        ribbon="danger"
        title="No access"
        onPrimaryAction={this.reloadPage}
        onSecondaryAction={this.onCloseModal}
        onCloseModal={this.onCloseModal}
        primaryButtonText="Request access"
        width="600px"
      >
        Your access to this account has expired.
      </UIModalConfirmation>
    )
  }
}
